// name: ProductCard
// description: Car for product and read information 
// author: OsmaroBonilla
// date 11/10/2021
import React, { useState } from 'react';
//Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import "./style.css"
import enterprisesAPI from '../../api/enterprise';
import toastMsg from '../Toast';
import { InfinitySpin } from  'react-loader-spinner'

const EnterpriseList = ({enterprisesList = [], setEnterprisesList}) => {

    const [loader, setLoader] = useState('');

    const handleStatus = async (enterprise) => {
        setLoader(enterprise.id);
        const dataRes = await enterprisesAPI.status(enterprise,enterprisesList);
        if(dataRes.error != ""){
            toastMsg.errorToast("No se pudo desactivar la empresa comunicate con tu proveedor de servicio")
        }else{
            setEnterprisesList(dataRes.data);
            setLoader('')
        }
    }

    const HeaderTable = () => {
        return(
            <div className="col-12 text-sm m-1 product-card" key="header-table-enterprise">
            <div className="card border-black p-1">
            <div className="row align-items-center">
                <div className="col-12 col-lg-2 col-md-2 col-sm-12 ">
                    <span className="bold-text ">Nombre </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">Descripcion </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">Direccion </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">Email </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">Tel </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">Acciones </span>
                </div>
            </div>
            </div>
            </div>
        )
    }

    const LoadTable = () => {
        return(
            <>
            <div className="col-12 text-sm m-1 product-card parpadea">
            <div className="card border-black p-1 pt-2 pb-2">
            <div className="row align-items-center">
                <div className="col-12 col-lg-2 col-md-2 col-sm-12 ">
                    <span className="bold-text ">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
            </div>
            </div>
            </div>
            <div className="col-12 text-sm m-1 product-card parpadea">
            <div className="card border-black p-1 pt-2 pb-2">
            <div className="row align-items-center">
                <div className="col-12 col-lg-2 col-md-2 col-sm-12 ">
                    <span className="bold-text ">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
            </div>
            </div>
            </div>
            <div className="col-12 text-sm m-1 product-card parpadea">
            <div className="card border-black p-1 pt-2 pb-2">
            <div className="row align-items-center">
                <div className="col-12 col-lg-2 col-md-2 col-sm-12 ">
                    <span className="bold-text ">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
                <div className="col-12 col-lg-2 col-md-2 col-sm-12">
                    <span className="bold-text">- </span>
                </div>
            </div>
            </div>
            </div>
            </>
            
        )
    }

    const ButtonActions = ({enterprise}) => {
        if(loader == enterprise.id)
            return(<InfinitySpin width='80'color="#6389FF"/>)

        return(
        <div className="container">
        <div className="row">
        <div className="col-4"><button className="btn"><FontAwesomeIcon icon={faPen}/></button></div>
        <div className="col-4"><button className="btn" onClick={() => handleStatus(enterprise)}><FontAwesomeIcon icon={faTrash}/></button></div>
        </div>
        </div>
        )
    }

    const BodyTable = () => {
        const list = enterprisesList.map((enterprise)=>{
            return(
            <div className="col-12 text-sm m-1 product-card" key={enterprise.id}>
            <div className={`card border-black p-1 ${!enterprise.enabled ? 'bg-disabled' : ''}`}>
                <div className="row align-items-center p-1">
                    <div className="col-12 col-lg-2 col-md-2 col-sm-12">{enterprise.name}</div>
                    <div className="col-12 col-lg-2 col-md-2 col-sm-12">{enterprise.description}</div>
                    <div className="col-12 col-lg-2 col-md-2 col-sm-12">{enterprise.address}</div>
                    <div className="col-12 col-lg-2 col-md-2 col-sm-12">{enterprise.email}</div>
                    <div className="col-12 col-lg-2 col-md-2 col-sm-12">{enterprise.tel}</div>
                    <div className="col-12 col-lg-2 col-md-2 col-sm-12"><ButtonActions enterprise={enterprise}/></div>
                </div>
            </div>
            </div>
            )
        })
        return list;
    }
    return(
        <>
        <HeaderTable />
        {enterprisesList.length > 0 ? <BodyTable />: <LoadTable />}
        </>
    )
}

export default EnterpriseList