/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"

//Importamos los componentes a utilizar
import NavBar from "../Navbar"

//Importamos todos los estilos
import "bootstrap/dist/css/bootstrap.min.css";
import "../Layout/layout.css"
import "../Navbar/navbar.css"


const Layout = ({ children }) => {
  return (
    <>
      <NavBar />
      <main>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
