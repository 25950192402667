import React, {useState} from "react";
import { Button, Modal } from'react-bootstrap';
import toastMsg from '../../components/Toast';
import enterpriseAPI from '../../api/enterprise';
import langSelected from '../../lang/lang-selected';

const ModalFrmEnterprise = ({show,handleClose,setEnterprisesList, enterprisesList}) => {

    const lang = langSelected();
    // state in forms
    const [enterprise, setEnterprise] = useState({
        id:'',
        name:'',
        description:'',
        address:'',
        email:'',
        tel:'',
        pictures:[],
        enabled:true,
    })

    const handleSave = async () => {
        handleClose();
        const res = await enterpriseAPI.add(enterprise,enterprisesList);
        if(res.error != ""){
            toastMsg.errorToast(res.error);
        }else{
            setEnterprisesList(res.data);
        }
    }

    return(
        <Modal show={show} onHide={handleClose} size='xl'>
        <Modal.Body>
            <div className="container">
                <div className="row">
                <div className="col-12">
                <h3>{lang.titleEnterprise}</h3>
                </div>
                <div className="col-12">
                <small className="form-text">Los campos que tengan asterisco(*) son obligatorios.</small>
                </div>
                </div>
            </div>
            <form action="">
                <div className="row m-2">
                    <div className="col-6">
                        <label htmlFor="txtName" className="form-label">Nombre*</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Ingresa nombre" 
                            id="txtName"
                            value={enterprise.name}
                            onChange={(e)=>{
                                setEnterprise({...enterprise,name:e.target.value});
                            }}
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-12">
                        <label htmlFor="txtName" className="form-label">Descripción*</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Ingresa una descripción" 
                            id="txtDescription"
                            value={enterprise.description}
                            onChange={(e)=>{
                                setEnterprise({...enterprise,description:e.target.value});
                            }}
                        />
                    </div>
                </div>
                <div className="row m-2">
                    <div className="col-12">
                        <label htmlFor="txtSKU" className="form-label">Direccion*</label>
                        <input 
                            type="text"
                            className="form-control"
                            placeholder="Ingresa elSKU" 
                            id="txtSKU" 
                            value={enterprise.address}
                            onChange={(e)=>{setEnterprise({...enterprise,address:e.target.value});
                            }}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="txtPrice" className="form-label">Correo*</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Ingresa el precio" 
                            id="txtPrice" 
                            value={enterprise.email}
                            onChange={(e)=>{
                                setEnterprise({...enterprise,email:e.target.value});
                            }}
                            />
                    </div>
                    <div className="col-6">
                        <label htmlFor="txtPrice" className="form-label">Tel*</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="Ingresa el precio" 
                            id="txtPrice" 
                            value={enterprise.tel}
                            onChange={(e)=>{
                                setEnterprise({...enterprise,tel:e.target.value});
                            }}
                            />
                    </div>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" className='secondary-color' onClick={handleClose}>
            Cancelar
        </Button>
        <Button 
            variant="primary" 
            className='primary-color'
            onClick={()=> handleSave()}>
            Guardar
        </Button>
        </Modal.Footer>
        </Modal>
    )
}

export default ModalFrmEnterprise