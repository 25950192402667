import {store} from '../components/Firebase/firebase-config'

const enterprisesAPI = {};

enterprisesAPI.readAll = async () => {
    const enterprises =  store.collection('enterprises');
    const data =  await enterprises.get()
    let list = [];
    data.forEach(doc =>list.push({...doc.data(),id:doc.id}))
    return list;
};

enterprisesAPI.add = async (enterprise, enterprisesList) =>{
    // recolect information
    let res ={
        data:[],
        error:"",
        devError:"",
    };
    await store.collection('enterprises').add(enterprise)
    .then(async (doc)=>{
        let list = [];
        enterprisesList.forEach(item =>list.push(item))
        list.push({...enterprise,id:doc.id});
        res.data = list;
    })
    .catch((err)=>{
        res.devError = err;
        res.error = "No pudo finalizar el guaardado";
    })
    return res;
}

enterprisesAPI.status = async (enterprise,enterprisesList) =>{
    // recolect information
    const dbRef = store.collection('enterprises').doc(enterprise.id);
    let res ={
        data:[],
        error:"",
    };
    await dbRef.set({
        ...enterprise,
        enabled:!enterprise.enabled
    })
    .then(async ()=> {
        let newData = enterprisesList.filter((item) => {return item.id != enterprise.id});
        newData.push({
            ...enterprise,
            enabled:!enterprise.enabled
        })
        res.data = newData;
    })
    .catch((err)=> res.error = err)
    return res;
}

export default enterprisesAPI;