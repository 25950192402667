// name: NavBar
// description: NavBar for maganement
// author: OsmaroBonilla
// date 11/10/2021
import React from 'react';
import {Navbar, Nav,Image} from 'react-bootstrap'
import '../Navbar/navbar.css';
//Import icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faTag, faLink, faTags } from '@fortawesome/free-solid-svg-icons'

const NavBar = (props) => {
    return(
        <Navbar collapseOnSelect expand="lg" className="bg-navbar nb-text" variant="dark">
        <Navbar.Brand href="/"><Image src='https://i.postimg.cc/SN6JL7JG/Logo-fondo-transp-argos-16.png' className="ajust-logo" alt="logo-argos" /></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="mr-auto">
          </Nav>
          <Nav>
            <Nav.Link href="/en-proceso" className="size-icon"><FontAwesomeIcon icon={faHome} className="size-icon"/> Home</Nav.Link>
            <Nav.Link href="/mantenimiento-productos" className="size-icon"><FontAwesomeIcon icon={faTag} className="size-icon"/> Productos</Nav.Link>
            <Nav.Link href="/en-proceso" className="size-icon"><FontAwesomeIcon icon={faTags} className="size-icon"/> Categorias</Nav.Link>
            <Nav.Link href="/en-proceso" className="size-icon"><FontAwesomeIcon icon={faLink} className="size-icon"/> Links</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
}

export default NavBar