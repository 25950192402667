import React, { useState } from 'react';
import Layout from "../components/Dashboard/Layout/layout"
import Seo from "../components/seo"
import '../components/css/mantenimiento-productos.css';
import { Col, Row, Container, Card } from'react-bootstrap';


// Import components
// Import icons
import { FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlus, faIdCardAlt } from '@fortawesome/free-solid-svg-icons'
import Access from "../components/Acess"

import enterpriseAPI from '../api/enterprise';
import langSelected from '../lang/lang-selected';
import EnterpriseList from '../components/enterprises/list'
import ModalFrmEnterprise from '../components/enterprises/ModalFrmEnterprise'
import '../components/css/global.css'



const Enterprises = () => {
    const lang = langSelected();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [enterprisesList, setEnterprisesList] = useState([]);
    const [filter, setFilter] = useState("");

    useState(async ()=>{
        if(enterprisesList.length == 0){
            const enterprises = await enterpriseAPI.readAll();
            setEnterprisesList(enterprises);
        }
    },[enterprisesList])


    return(
        <>
        <Layout>
            <Seo title="Mantenimiento de productos" />
            <Access section="product"/>

                <ModalFrmEnterprise show={show} handleClose={handleClose} setEnterprisesList={setEnterprisesList} enterprisesList={enterprisesList}/>
                {/* End Modal addProduct */}
                <Container fluid>
                    <Row style={{
                        "backgroundColor": "#6389FF", 
                        "borderBottomLeftRadius": "30px", 
                        "borderBottomRightRadius": "30px"
                    }}>
                    <Col md={3}>
                        <Card className=" mt-3 mb-3 shadow" style={{"borderRadius": "15px"}}>
                            <Card.Body>
                            <Card.Title style={{"color": "#5C5C5C"}}>
                                <FontAwesomeIcon icon={faIdCardAlt} style={{"fontSize": "30px"}} /> {lang.headerEnterprise}
                            </Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    </Row>
                </Container>
                <div className="container-fluid">
                    <div className="row m-4">
                    {/* title and subtitle*/}
                    <div className="col-12 m-2">
                        <h2>{lang.titleEnterprise}</h2>
                        <p className="subtitle">{lang.descriptionEnterprise}</p>
                    </div>
                    {/* Search and back button*/}
                    <div className="col-12 block-position">
                        <div className="row">
                            <div className="col-6">
                            <input type="text" className="form-control search-control"  placeholder='Buscar'/>
                            </div>
                            <div className="col-2">
                            <button className="btn btn-outline-dark add-button" onClick={handleShow}>
                                <FontAwesomeIcon icon={faPlus} className="size-button-icon"/>
                            </button>
                            </div>
                        </div>
                    </div>
                    <EnterpriseList enterprisesList={enterprisesList} setEnterprisesList={setEnterprisesList}/>
                    </div>
                </div>
        </Layout> 
        </>
    )
    
  
}

export default Enterprises
